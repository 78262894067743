<template>
    <v-container fluid ma-0 pa-0 fill-height align-start>
      <v-row no-gutters align="start" :class="{['fill-height']:$vuetify.breakpoint.mdAndUp}">

        <v-col cols="12" md="2" :class="[{['fill-height']:$vuetify.breakpoint.mdAndUp}, $style.navigation]" ><!--:style="{'min-height':$height+'px'}"-->
            <div :class="[$style.navigation_label]" @click="expand.navigation = !expand.navigation">
                <str :index="'navigation > menu > navigate'"/>
                <v-icon v-if="allow" :class="[$style.expander,{[$style.expander_collapse]:!expand.navigation}]">mdi-triangle</v-icon>
            </div>

            <div v-if="expand.navigation" @click="main_content='topics';tabs.admin=null;">
              <template v-for="(program, program_index) in my_programs">
                <div v-if="program.active=='Y' || allow" @click="tabs.navigation=program_index;" :class="[$style.tab, {[$style.tab_selected]:program_index==tabs.navigation}, {[$style.inactive_program]:program.active=='N'}]" :key="'program_tab_'+program_index">
                  {{program.name[language]}}
                </div>
              </template>
            </div>

            <!-- ADMIN -->
            <div :class="$style.navigation_label" v-if="allow" @click="expand.admin = !expand.admin">
                Admin
                <v-icon :class="[$style.expander,{[$style.expander_collapse]:!expand.admin}]">mdi-triangle</v-icon>
            </div>

            <div v-if="expand.admin" @click="main_content='admin';tabs.navigation=null;">
                <div @click="tabs.admin='UserManagement'" :class="[$style.tab, {[$style.tab_selected]:tabs.admin=='UserManagement'}]">
                  User Management
                </div>
                <div @click="tabs.admin='ContentManagement'" :class="[$style.tab, {[$style.tab_selected]:tabs.admin=='ContentManagement'}]">
                  Content Management
                </div>
                <div @click="tabs.admin='DataDashboard'" :class="[$style.tab, {[$style.tab_selected]:tabs.admin=='DataDashboard'}]">
                  Data Dashboard
                </div>
            </div>


        </v-col>


        <v-col cols="12" md="10" :class="$style.resources"><!-- :style="{'height':$height+'px'}"-->
          <template v-if="program && main_content=='topics'">
            <Topics :program="program" :language="language"/>
            <EditorialBoard/>
          </template>
          <v-container v-else-if="tabs.admin==null && !program">
            <v-row>
              <v-col cols="12" md="12" style="text-align: center;">
                <b><str index="message > no_active_programs"/></b>
              </v-col>
            </v-row>
          </v-container>
          
          <Admin v-if="main_content=='admin'" :debug="debug" :tab="tabs.admin" :programs="programs" :language="language"/>
        </v-col>
      </v-row>
    </v-container>

</template>

<script>
import Topics from '@/components/Topics/Topics.vue'
import EditorialBoard from '@/components/EditorialBoard/EditorialBoard.vue'
import Admin from '@/views/Admin.vue'

export default {
  props: {
    debug: {
      type: Boolean,
      required: false,
      default: false
    },
    language: {
      type: String,
      required: true
    },
    programs: {
      type: Array,
      required: true
    },
    my_programs: {
      type: Array,
      required: true
    }
  },
  components: {
    EditorialBoard,
    Topics,
    Admin
  },
  created: function(){
    let my_programs = this.my_programs;
    for(let i=0; i<my_programs.length; i++){
      let program = my_programs[i];
      if(program.active=='Y' || this.allow){
        this.tabs.navigation = i;
        break;
      }
    }
  },
  data: function(){
    return {
      tabs: {
        navigation: null,
        admin: null
      },
      expand: {
        navigation: true,
        admin: false
      },
      main_content: 'topics'
    }
  },
  computed: {
    program: function(){
      return this.my_programs.length>0 ? this.my_programs[this.tabs.navigation] : null
    },
    $height: function(){
      let header = document.getElementsByTagName('header')[0]
      return window.innerHeight - header ? header.offsetHeight : 150;
    },
    allow: function(){
      let allow_types = ['admin','staff'];
      let user = this.$store.getters.user;

      return this.in_array(user.type, allow_types)
    }
  }
}
</script>

<style module>
.navigation {
  background-color: var(--v-backgroundGrayAccent1-base);
  font-size: 18px;
  font-weight: 500;
}

.navigation_label {
  position: relative;
  width: 100%;
  padding-left: 8%;
  padding-top: 5px;
  color:  var(--v-brandGray1-base);
  cursor: pointer;
  min-height: 40px
}

.resources {
  padding-top: 20px !important;

}

.tab {
  width: 100%;
  min-height: 50px;
  padding: 13px 12px 5px 15%;
  cursor: pointer;
  transition: all 0.25s;
}

.tab_selected {
  background-color: var(--v-brandBlue-base);
  color: var(--v-brandWhite-base);
}

.expander {
  position: absolute !important; 
  right: 20px;
  top: 5px;
  transform: rotate(180deg);
  font-size: 1em !important;
}

.expander_collapse {
  transform: rotate(90deg);
}

.inactive_program{
  color: grey;
  font-style: italic;
}
.tab_selected.inactive_program{
  background-color: darkgrey;
}
@media only screen and (max-width: 960px){
    .tab {
        display: inline-table;
        padding: 1%;
        width: 33%;
        text-align: center;
        border: 1px solid var(--v-brandWhite-base);
        font-size: 0.75rem;
        height: 50px;
        /*
        display: inline-flex;
        justify-content: center;
        align-items: center;
       **/
    }
	
}
</style>
<style lang="css" scoped>
/deep/ .v-icon.v-icon {
    line-height: 1.5;
}
</style>