<template>
<div>
    <v-app-bar flat>
      <div class="d-flex align-center">
        <h3>
            Program List
        </h3>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <v-btn
          style="margin: 15px;"
          color="var(--v-brandOrange-base)"
          dark
          @click="newProgram"
        >
          New Program
        </v-btn>
          
      </div>
    </v-app-bar>


    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step step="1">Program List</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2">Program Topics</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <!-- PROGRAM SELECTION -->
            <v-stepper-content step="1">
                <v-data-table :class="$style.table" :headers="headers" :items="program_records"
                    :loading-text="'...loading...'"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    :footer-props="{
                        itemsPerPageOptions: [50,100,200,-1]
                    }"
                    :multi-sort="true"
                    class="elevation-1 tracker_table"
                    dense     
                    >

                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item, item_index) in items" :key="'program_'+item_index" :class="[{[$style.program_inactive]:item.active=='N'}]">
                            <template v-for="(column,index) in headers">
                                <td :key="'td_'+index"  @click="select_program(item_index);">
                                    <template v-if="index==0">{{item[column.value]}}</template>
                                    <template v-else>
                                        <div v-for="(thing, language) in languages" :key="'program_'+item.id+'_'+index+'_'+language">
                                            <template v-if="column.value=='name'">
                                                {{language}}:  
                                            </template>
                                            {{item[column.value][language]}}
                                        </div>
                                    </template>
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </template>

                </v-data-table>
            </v-stepper-content>

            <!-- PROGRAM EDITOR -->
            <v-stepper-content step="2">
                <v-btn text @click="step--"><v-icon left>mdi-arrow-left</v-icon> Back</v-btn>
                <v-container v-if="editing">
                    <v-row>
                        <v-col cols="12" lg="1">
                            Program Name:
                            <v-switch v-model="editing.active" true-value="Y" false-value="N" :label="'ACTIVE'"/>
                        </v-col>

                        <v-col cols="12" :lg="10/$root.objectLength(languages)" v-for="(language_thing, language) in languages" :key="'col_program_name_'+language">
                            <strong>{{language}}</strong>
                            <v-text-field type="text" outlined v-model="editing.name[language]" :label="'Program Name'"/>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" lg="11" style="text-align:right;">
                            <v-btn color="var(--v-brandOrangeAccent1-base)" @click="newTopic">Add Topic</v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" lg="12">
                            <draggable class="list-group" :style="$style.draggable" :group="'topics'" :list="editing.topics" handle=".handle">
                                <template v-for="(item, item_index) in editing.topics">              
                                    <div
                                        :class="$style.draggable_item"                                         
                                        :key="'item_'+item_index"
                                        >
                                        
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" lg="1"><template v-if="editing.topics.length>1"><v-icon class="handle">mdi-menu</v-icon> Topic {{item_index+1}}:</template> </v-col>
                                                <v-col cols="12" :lg="10/$root.objectLength(languages)" v-for="(language_thing, language) in languages" :key="'topic_'+item_index+'_name_'+language">
                                                    <v-text-field  type="text" outlined v-model="editing.topics[item_index].name[language]" style="width: 100%;" :label="'Topic'"/>
                                                    
                                                    <v-container class="pt-0" v-if="resource_toggle!=null && editing.id">
                                                        <v-row>
                                                            <v-col cols="12" lg="4" v-for="(resource, resource_index) in $topic_resources" :key="'topic_resource_'+resource_index">
                                                                <p class="ma-0">{{resource.label}}</p>
                                                                <v-switch class="ma-0 pa-0" v-model="resource_toggle[item_index][resource.type][language]" :true-value="true" :false-value="false" @change="toggleResource(item_index, language, resource.type)"  style="display: inline-block;"/>
                                                                <template v-if="resource_toggle[item_index][resource.type][language]">
                                                                    <ul>
                                                                        <li v-for="(file, file_index) in editing.topics[item_index].files[resource.type][language]" :key="'file_list_'+item_index+'_'+file_index+'_'+language">{{file}}</li>
                                                                    </ul>
                                                                    <UploadFile
                                                                        :action="'admin'"
                                                                        :call="'upload'"
                                                                        :language="language"
                                                                        @uploaded="uploaded"
                                                                        :config="{topic_index:item_index, language:language, name:resource.type,accept:resource.accept,multiple:resource.multiple}" 
                                                                        :folder="'/'+$program_id+'/'+item_index+'/'+resource.type"/>
                                                                </template>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                    
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </div>
                                </template>
                            </draggable>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" style="text-align:center;">
                            <v-btn color="var(--v-brandOrange-base)" dark @click="save">{{editing.id===null ? 'SAVE' : 'UPDATE'}}</v-btn>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12" lg="12">
                            <h5 align='center'>TOPIC TRASH</h5>
                            <draggable class="list-group" :class="$style.draggableTrash" :list="trash.topics" :group="'topics'">
                                <template v-for="(item, item_index) in trash.topics">
                                    <div
                                        :class="[$style.draggable_item, $style.deleted_item]"     
                                        :key="'delete_item_'+item_index"
                                        >
                                        <v-icon class="handle">mdi-menu</v-icon>

                                        <template v-for="(language_thing, language) in languages">
                                            {{language}}: {{trash.topics[item_index].name[language]}}
                                        </template>

                                    </div>
                                </template>
                            </draggable>
                        </v-col>
                    </v-row>
                </v-container>
            </v-stepper-content>


            <v-stepper-content step="3">

            </v-stepper-content>            

        </v-stepper-items>

    </v-stepper>

    <v-snackbar v-model="snackbar.display">
        {{snackbar.message}}


        <template v-slot:action="{ attrs }">
            <v-btn
            text
            v-bind="attrs"
            @click="snackbar.display = false"
            >
            Close
            </v-btn>
        </template>        
    </v-snackbar>



</div>
</template>

<script>
import draggable from "vuedraggable";
import UploadFile from "@/components/Upload/UploadFile.vue"

export default {
    props: {
        language: {
            type: String,
            required: true
        },
        programs: {
            type: Array,
            required: true
        }
    },
    components: {
        draggable,
        UploadFile
    },
    data: function(){
        return {
            editing: null,
            resource_toggle: null,
            program_selection: [],
            step: 1,
            trash: {
                topics: []
            },
            snackbar: {
                display: false,
                message: null
            }
        }
    },
    methods: {
        select_program: function(index){
            this.editing=this.unlink(this.programs[index]);
            this.step++;
        },
        labelTemplate: function(){
            let languages = this.languages;
            let label = {};
            for(let language in languages){
                label[language] = null;
            }
            return label;
        },
        newProgram: function(){
            this.editing = this.newProgramTemplate();
            this.step++;
        },
        newProgramTemplate: function(){
            return {
                id: null,
                name: this.labelTemplate(),
                topics: [this.newTopicTemplate()]
            }
        },
        newTopic: function(){
            this.editing.topics.push(this.newTopicTemplate());
            this.init_resource_toggles();
        },
        newTopicTemplate: function(){
            return {
                name: this.labelTemplate(),
                files: {
                    ppt: this.labelTemplate(),
                    preview: this.labelTemplate(),
                    video: this.labelTemplate()
                }
            }
        },
        init_resource_toggles: function(){
            let editing = this.editing;
            let topics = editing.topics;

            this.resource_toggle = [];
            for(let i=0; i<topics.length; i++){
                let topic = topics[i];
                let files = topic.files;
                
                this.resource_toggle[i] = {
                    ppt: this.labelTemplate(),
                    preview: this.labelTemplate(),
                    video: this.labelTemplate(),
                }

                for(let type in files){
                    let resources = files[type];
                    for(let language in resources){
                        let filenames = resources[language];
                        if(filenames){
                            this.resource_toggle[i][type][language] = true;
                        }else{
                            this.resource_toggle[i][type][language] = false;
                        }
                    }
                }
            }
        },
        toggleResource: function(index, language, type){
            this.editing.topics[index].files[type][language] = this.resource_toggle[index][type][language] ? {} : null;
            this.$forceUpdate();
        },
        resource_count: function(language, type, topics){
            let counter = 0;
            for(let t=0; t<topics.length; t++){
                let topic = topics[t];
                let files = topic.files[type][language];
                if(files!=null){
                    counter+=files.length;
                }
            }
            return counter;
        },
        uploaded: function(data){
            let config = data.config;
            let files = data.files;
            this.editing.topics[config.topic_index].files[config.name][config.language] = [];
            for(let i=0; i<files.length; i++){
                let file = files[i];
                this.editing.topics[config.topic_index].files[config.name][config.language].push(file.name);
            }
            let self = this;
            setTimeout(function(){
                self.save();
            },2000);
        },
        save: function(){
            let self = this;
            let action = 'admin';
            let call = 'save_program';
            self.sendRequest({
                action: action,
                call: call,
                data: self.editing
            }).then(function(response){
                self.snackbar.message = response.data[action][call].result ? 'SAVED PROGRAM' : 'ERROR';
                self.snackbar.display = true;
                self.editing.id = self.editing.id ? self.editing.id : response.data[action][call].program_id;
                self.$controller.service.send('fetch.programs');
            },function(response){
                self.snackbar.message = response.data[action][call].result ? 'SAVED PROGRAM' : 'ERROR';
                self.snackbar.display = true;
            })
        }
    },
    computed: {
        languages: function(){
            return this.$store.getters.library.languages;
        },
        headers: function(){
            let headers = [
                {text:'Active',value:"active"},
                {text:'Program Name',value:"name"},
                {text:'Topics',value:"topics"},
                {text:'PPTs',value:"ppts"},
                {text:'Videos',value:"videos"}
            ];
            return headers;
        },
        $program_id: function(){
            let editing = this.editing;
            return editing && editing.id ? editing.id : null;
        },
        program_records: function(){
            let records = [];
            let programs = this.programs;

            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                records.push({
                    active: program.active,
                    name: program.name,
                    topics: {
                        en: program.topics.length,
                        fr: program.topics.length,
                    },
                    ppts: {
                        en: this.resource_count('en','ppt',program.topics),
                        fr: this.resource_count('fr','ppt',program.topics),
                    },
                    videos: {
                        en: this.resource_count('en','video',program.topics),
                        fr: this.resource_count('fr','video',program.topics),
                    }
                })
            }

            return records;
        },
        $topic_resources: function(){
            let resources = [
                {type:'ppt',accept:'.ppt,.pptx',multiple:false,label:'PowerPoint'},
                {type:'preview',accept:'.png',multiple:true,label:'PPT Preview'},
                {type:'video',accept:'.mp4',multiple:false,label:'Video'}
            ];

            return resources;
        }
    },
    watch: {
        step: function(){
            let step = this.step;

            if(step==2){
                this.resource_toggle = [];
                this.init_resource_toggles();
            }else{
                this.resource_toggle = null;
            }
        }
    }
}
</script>

<style module>
.program_inactive{
    background-color: whitesmoke;
    color: darkgrey;
}

.table td:first-child{
    width: 100px;
    text-align: center;
}

.draggableTrash{
    width: 100%;
    min-height: 50px;
    border: 1px solid red;
}
</style>

<style lang="css" scoped>
/deep/ .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    color: var(--v-brandBlack-base) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    background: var(--v-backgroundGrayAccent1-darken1) !important;
}
</style>